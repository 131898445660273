import React from "react";

const Header = ({ backgroundImage, heading, paragraph }) => {
  return (
    <div className="lifeatgoken-header-wrapper">
      <div className="filter-div"></div>
      <img src={backgroundImage} alt="life at goken" />
      <div className="lifeatgoken-heading-wrapper">
        <h1 className="bold-heading">{heading}</h1>
        <p dangerouslySetInnerHTML={{ __html: paragraph }} className="para"></p>
      </div>
    </div>
  );
};
export default Header;
