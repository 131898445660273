import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../molecules/LifeAtGoken/Header";
import bannerImage from "../images/lifeatgoken/lifeatgoken.png";
import { graphql, useStaticQuery } from "gatsby";
import WorkWithUs from "../molecules/LifeAtGoken/WorkWithUs";
import HalfImageContainer from "../molecules/LifeAtGoken/HalfImageContainer";
import AboutUsTestimonial from "../molecules/aboutus/AboutUsTestimonial";
import SliderCarousel from "../molecules/homepage/HomepageCareers/slider";
const Lifeatgoken = () => {
  const data = useStaticQuery(graphql`
    query LifeAtGokenQuery1 {
      gcms {
        lifeAtGokens {
          insightPages {
            title
            slug
            shortDescription
            image {
              url
            }
          }
          paragraph
          tabsImages {
            url
          }
          tabImagesAlt
          tabsText
          title
          tabsParagraph
          video {
            url
          }
          videoThumbnail {
            url
          }
        }
        aboutUses {
          testimonialCards {
            companyName
            designation
            logoImage {
              url
            }
            paragraph
            selectCountry
          }
        }
      }
    }
  `);
  return (
    <Layout isNavWhite={true}>
      <SEO
        canonicalLink={"https://www.goken-global.com/experiencelifeatgoken/"}
        title="Experience Life at Goken: Life at Goken - Careers"
        description={
          "Discover what it's like to be part of Goken. A culture of innovation, teamwork, and personal growth awaits. Click to explore life at Goken!"
        }
        keywords={
          "experience, creativity, challenging, recognition, visibility, innovation, initiative, leverage, careers, engineering, business, management, training, development"
        }
      />
      <div className="lifeatgoken-page-wrapper">
        <Header
          backgroundImage={bannerImage}
          heading={data.gcms.lifeAtGokens[0].title}
          paragraph={data.gcms.lifeAtGokens[0].paragraph}
        />
        <WorkWithUs
          video={data.gcms.lifeAtGokens[0].video.url}
          heading={"Step inside our office"}
          thumbnail={data.gcms.lifeAtGokens[0].videoThumbnail.url}
          paragraph={`Based in Ohio, Goken is an engineering and staffing solutions provider fuelled by exceptional talent who partner with companies to solve challenging problems, accelerate development cycles and build better products.`}
        />
        <HalfImageContainer
          headings={data.gcms.lifeAtGokens[0].tabsText}
          images={data.gcms.lifeAtGokens[0].tabsImages}
          paragraphs={data.gcms.lifeAtGokens[0].tabsParagraph}
          altTexts={data.gcms.lifeAtGokens[0].tabImagesAlt}
        />

        <SliderCarousel />
      </div>
    </Layout>
  );
};
export default Lifeatgoken;
