import React from "react";
import Description from "./Description";
import JoinTeamCTA from "./JoinTeamCTA";

const WorkWithUs = ({ heading, paragraph, video, thumbnail }) => {
  return (
    <div className="lifeatgoken-workwithus-wrapper w-90-wrapper">
      <Description
        thumbnail={thumbnail}
        heading={heading}
        video={video}
        paragraph={paragraph}
      />

      <JoinTeamCTA
        heading={"Work With Us"}
        buttonText={"Explore Jobs"}
        link="/careers/"
      />
    </div>
  );
};

export default WorkWithUs;
