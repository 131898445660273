import React from "react";

const Description = ({ heading, paragraph, video, thumbnail }) => {
  return (
    <div className="lifeatgoken-description-wrapper">
      <div className="description-header-wrapper">
        <h2 className="heading">{heading}</h2>
        <p className="para">{paragraph}</p>
      </div>

      <div className="video-wrapper">
        <video
          src={video}
          controls={true}
          controlsList={true}
          muted
          preload={"auto"}
          poster={thumbnail}
        ></video>
      </div>
    </div>
  );
};

export default Description;
