import React from "react";

const ImageDescription = ({
  index,
  heading,
  image,
  description,
  className = "",
  altText,
}) => {
  return (
    <div className={`lifeatgoken-image-description-wrapper ${className}`}>
      <div className="desktop-wrapper">
        {index % 2 === 0 ? (
          <>
            <div className="image-description-wrapper">
              <img src={image.url} alt={altText ? altText : heading} />
            </div>
            <div className="paragraph-description-wrapper">
              <h3 className="heading">{heading}</h3>
              <p className="para">{description}</p>
            </div>
          </>
        ) : (
          <>
            <div className="paragraph-description-wrapper">
              <h3 className="heading">{heading}</h3>
              <p className="para">{description}</p>
            </div>
            <div className="image-description-wrapper">
              <img src={image.url} alt={altText ? altText : heading} />
            </div>
          </>
        )}
      </div>
      <div className="small-device-wrapper">
        <div className="image-description-wrapper">
          <img src={image.url} alt={altText ? altText : heading} />
        </div>
        <div className="paragraph-description-wrapper">
          <h3 className="heading">{heading}</h3>
          <p className="para">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default ImageDescription;
