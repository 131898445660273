import React, { useEffect, useRef, useState } from "react";

import Fade from "react-reveal/Fade";

import sliderCardIcon from "../../images/servicesassets/slider-card-icon.png";

// import {
//   CarouselProvider,
//   Slider,
//   Slide,
//   ButtonBack,
//   ButtonNext,
// } from "pure-react-carousel";
// import "pure-react-carousel/dist/react-carousel.es.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useLanguage } from "../../languageContext";

// import rightArren from "../../images/servicesassets/rightArrow-en.svg";
// import rightArrin from "../../images/servicesassets/rightArrow-in.svg";
// import rightArrja from "../../images/servicesassets/rightArrow-ja.svg";
import useOnScreen from "../../components/atoms/appearance/Appearanc";
import config from "react-reveal/globals";
const AboutUsTestimonial = ({ testimonialSlides, para, isHomepage }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const currentLanguage = useLanguage();
  console.log(testimonialSlides);
  // const ref = useRef();

  // const isVisible = useOnScreen(ref);
  // useEffect(() => {}, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 10000, min: 3000 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1600 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1600, min: 714 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 714, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <button onClick={previous}>Prev</button>
        <button onClick={next}>Next</button>
      </div>
    );
  };

  // async function addFadeEffect(event) {
  //   try {
  //     console.log(event);
  //     let child = event.target;
  //     const element = child.closest(".carousel-slider-wrapper");
  //     element.classList.add("testimonial-opacity");
  //     const otherElements = document.querySelectorAll(".active-opacity");
  //     console.log("Other Elements are");
  //     otherElements.forEach((element) => {
  //       element.classList.remove("active-opacity");
  //     });
  //     const targetElement = child.closest("li");
  //     console.log(targetElement);
  //     targetElement.classList.add("active-opacity");
  //     // const element = document.querySelectorAll(".carousel-slider-wrapper");
  //     console.log(element, "is the one");
  //   } catch (error) {}
  // }

  const hoverHandler = (event) => {
    // const element = document.querySelectorAll(".testimonial-item");
    // console.log(event);
    // addFadeEffect(event);
    // const catchEventDiv = event.target;
    // const catchEvenList = event.target.classList.value;
    // event.target.classList.remove("testimonial-opacity");
  };

  const removeHoverHandler = (event) => {
    // console.log(event, "remove hover handler");
    // let child = event.target;
    // const element = child.closest(".carousel-slider-wrapper");
    // element.classList.remove("testimonial-opacity");
    // const otherElements = document.querySelectorAll(".active-opacity");
    // console.log("Other Elements are");
    // otherElements.forEach((element) => {
    //   element.classList.remove("active-opacity");
    // });
    // const element = document.querySelectorAll(".testimonial-item");
    // element.forEach((card) => {
    //   if (card.classList.contains("testimonial-opacity")) {
    //     card.classList.remove("testimonial-opacity");
    //   }
    // });
  };

  return (
    <div
      className={`section-br p-bottom-3em read-more read-${
        isHomepage ? "section m-top-5" : ""
      }`}
    >
      {/* <Fade cascade opposite> */}{" "}
      <div className="w-90-wrapper abouts-testimonial-container">
        <div className="aboutus-testimonial-section ">
          <h2 className="heading">CLIENT TESTIMONIALS</h2>
          {para ? <p className="para">{para}</p> : ""}

          <div
            className="slider-wrapper carousel-slider-wrapper"
            onMouseEnter={() => setIsPlaying(false)}
            onMouseLeave={() => setIsPlaying(true)}
          >
            <Carousel
              arrows={false}
              customButtonGroup={<CustomButtonGroupAsArrows />}
              responsive={responsive}
              autoPlay={isPlaying}
              infinite={true}
              autoPlaySpeed={5000}
            >
              {testimonialSlides &&
                testimonialSlides.map(
                  ({
                    companyName,
                    designation,
                    logoImage,
                    paragraph,
                    selectCountry,
                    index,
                  }) => (
                    <div index={index}>
                      {/* <Fade> */}
                      <div
                        className="testimonial-item box-shadow"
                        onMouseEnter={(event) => hoverHandler(event)}
                        onMouseLeave={(event) => removeHoverHandler(event)}
                      >
                        {logoImage ? (
                          <img src={logoImage.url} alt={designation}></img>
                        ) : (
                          <img
                            className="slider-card-icon"
                            src={sliderCardIcon}
                            alt={designation}
                          ></img>
                          // <div className={`testimonial-icon bg-en`}></div>
                        )}
                        <p className="para">{paragraph}</p> <br></br>
                        <div className="name-wrapper semi-title">
                          <h2 className="designation semi-title no-margin">
                            {designation}
                          </h2>

                          <h3 className="semi-title regular no-margin">
                            {companyName}
                          </h3>
                        </div>
                      </div>
                      {/* </Fade> */}
                    </div>
                  )
                )}
              <div>
                {/* <Fade> */}
                <div
                  className="testimonial-item box-shadow"
                  onMouseEnter={(event) => hoverHandler(event)}
                  onMouseLeave={(event) => removeHoverHandler(event)}
                >
                  <img
                    className="slider-card-icon"
                    src={sliderCardIcon}
                    alt={"comapny logo for Electric Vehicle OEM"}
                  ></img>
                  <p className="para">
                    “I have worked with Goken through various automotive OEMs in
                    the past and Goken's agility, quality, and ease to work with
                    remain consistent throughout every experience. Their
                    training program & engineering methods for new associates
                    allows them to hit the ground running without burdening
                    myself or my team. I am always at ease and confident in the
                    results I will get when working with them."{" "}
                  </p>{" "}
                  <br></br>
                  <h3 className="heading-margin name-wrapper semi-title">
                    {"Senior Director"}
                    <br></br>
                    <span>{"Electric Vehicle OEM"}</span>
                  </h3>
                </div>
                {/* </Fade> */}
              </div>
            </Carousel>
          </div>
        </div>
      </div>
      {/* </Fade> */}
    </div>
  );
};

export default AboutUsTestimonial;
