import React from "react";
import ImageDescription from "./ImageDescription";
import JoinTeamCTA from "./JoinTeamCTA";

const HalfImageContainer = ({
  headings = [],
  paragraphs = [],
  images = [],
  className,
  hideCTA = false,
  sectionHeading = false,
  altTexts = [],
}) => {
  console.log("Received images are", images[0]);
  return (
    <div className="half-image-para-wrapper w-90-wrapper">
      {sectionHeading && <h2 className="heading">{sectionHeading}</h2>}
      {headings.map((element, index) => (
        <ImageDescription
          heading={element}
          className={className}
          index={index}
          description={paragraphs[index] ? paragraphs[index] : null}
          image={images[index] ? images[index] : null}
          altText={altTexts[index] ? altTexts[index] : ""}
        />
      ))}
      {!hideCTA && (
        <JoinTeamCTA
          heading={"Let’s make it happen"}
          description={`
            The foundation of GOKEN is based on strong values, principles, and a
            vision - to deliver high-value results to our customers and build
            win-win relationships with our employees and customers. Our goal is
            to build an organization that's admired and respected for its values
            and vision while being strong in engineering and business
            applications. We at GOKEN value innovation, initiative, and
            leveraging employee strengths. Work here is challenging, rewarding,
            and inspiring. As a member of our team, you will have plenty of
            opportunities to gain recognition and visibility.
          `}
          paragraph={"Join our Team"}
          className={"gray-background"}
          link={"/careers/"}
          buttonText={"Search Jobs"}
        />
      )}
    </div>
  );
};

export default HalfImageContainer;
